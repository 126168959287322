<template>
    <v-autocomplete label="Type to search Zoho deals" :error="isInError" :error-messages="errorMessages" return-object
        prepend-icon="mdi-handshake-outline" :search-input.sync="search" :items="items" v-model="internalValue" hide-no-data
        hide-selected cache-items item-text="deal_Name" item-value="id" :loading="isLoading"></v-autocomplete>
</template>

<script>
export default {
    props: {
        value: {
            type: Object,
            default: () => { }
        },
        isInError: {
            type: Boolean,
            default: false
        },
        errorMessages: {
            type: String,
            default: ''
        }
    },
    watch: {
        search(newValue, oldValue) {
            clearTimeout(this.timeOutId);

            this.timeOutId = setTimeout(() => {
                this.getData();
            }, 150);
        },
        internalValue(newValue, oldValue) {
            this.$emit('input', newValue);
        },
        value() {
            if (this.value && this.search && this.search != this.value.deal_Name) {
                this.items.push(this.value);
                this.internalValue = this.value;
                this.search = this.value.deal_Name;
            }
        }
    },
    created() {
        this.getData();

        if (this.value) {
            this.search = this.value.deal_Name;
            this.items.push(zohoDeal);
        }
    },
    methods: {
        async getData() {
            try {
                this.isLoading = true;
                var data = await this.$courier.ZohoDeals.getData(1, 4, this.search);
                this.items = data;

                this.isLoading = false;
            } catch (exception) {
                console.warn('Error getting data from zoho deals api:' + exception);
            }
        }
    },
    data() {
        return {
            isLoading: true,
            timeOutId: '',
            search: null,
            items: [],
            internalValue: null,
        }
    },
}
</script>

<style></style>