<template>
    <v-autocomplete :error-messages="errorMessages" :error="isInError" prepend-icon="mdi-database-search" :label="label"
        hide-no-data hide-selected item-text="name" item-value="id" placeholder="Type to Search" return-object
        :loading="isLoading" :search-input.sync="search" :items="companies" v-model="internalValue">
    </v-autocomplete>
</template>

<script>
export default {
    props: {
        isInError: {
            type: Boolean,
            default: false,
        },
        errorMessages: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: 'Search Companies'
        },
        value: {
            type: Object,
            default: () => { }
        },
        maxResults: {
            type: Number,
            default: 5,
        }
    },
    watch: {
        search(newValue, oldValue) {
            clearTimeout(this.timeOutId);

            setTimeout(() => {
                this.getCompanies();
            }, 150);
        },
        internalValue(newValue, oldValue) {
            this.$emit('input', newValue);
        },
        value: {
            deep: true,
            handler(newValue, oldValue) {
                if (newValue && newValue.id) {
                    this.companies = [];
                    this.companies.push(newValue);
                    this.internalValue = newValue;
                }
            }
        }
    },
    created() {
        this.getCompanies();
    },
    methods: {
        getCompanies() {
            this.isLoading = true;
            this.$courier.Companies.getData(1, this.maxResults, this.search).then(response => {

                for (const company of response.payload) {
                    this.companies.push(company);
                }

                this.isLoading = false;
            });
        }
    },
    data() {
        return {
            timeOutId: '',
            isLoading: true,
            companies: [],
            internalValue: null,
            search: null,
        }
    },
}
</script>

<style></style>