<template>
  <v-card>
    <v-toolbar>
      <v-toolbar-title>
        <v-icon class="mr-2"> mdi-plus </v-icon>
        Add a new commission
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn
        v-if="$route.params.commissionId1"
        color="warning"
        :to="{
          name: 'View Commission',
          params: { commissionId: $route.params.commissionId },
        }"
      >
        <v-icon class="mr-2"> mdi-cancel </v-icon>
        Cancel Editing
      </v-btn>
    </v-toolbar>
    <v-card-text>
      <v-row>
        <v-col>
          <trev-company-search
            :isInError="hasClickedSave && !commission.forCompanyId"
            :errorMessages="
              hasClickedSave && !commission.forCompanyId
                ? 'Please select a company'
                : ''
            "
            v-model="forCompany"
          ></trev-company-search>
        </v-col>
        <v-col>
          <v-menu
            v-model="showingLastQuarterChangeDatePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="commission.lastQuarterChange"
                label="Commission Date"
                :error-messages="
                  hasClickedSave && !commission.lastQuarterChange
                    ? 'Please enter a date'
                    : ''
                "
                prepend-icon="mdi-calendar"
                :error="hasClickedSave && !commission.lastQuarterChange"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="commission.lastQuarterChange"
              @input="showingLastQuarterChangeDatePicker = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <trev-commission-type
            v-model="commission.type"
            :isInError="hasClickedSave && !commission.type"
            :errorMessages="
              hasClickedSave && !commission.type ? 'Please select a type' : ''
            "
          ></trev-commission-type>
        </v-col>
        <v-col>
          <v-select
            :error="hasClickedSave && !commission.rate"
            :error-messages="
              hasClickedSave && !commission.rate ? 'Please select a rate' : ''
            "
            label="Commission Rate"
            prepend-icon="mdi-ticket-percent-outline"
            v-model="commission.rate"
            :items="getCommissionRateItems"
          ></v-select>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col v-show="commission.type !== 'Rebate'">
          <trev-xero-invoice-search
            v-model="invoice"
            :inError="hasClickedSave && !commission.xeroTenantId"
            :disabled="!commission.xeroTenantId"
            :formMessage="
              !commission.xeroTenantId ? 'Please select a company' : ''
            "
            ref="ddlInvoiceSearch"
            :errorMessages="
              hasClickedSave && !commission.xeroTenantId
                ? 'Please enter an invoice number'
                : ''
            "
          ></trev-xero-invoice-search>
        </v-col>
      </v-row>
      <v-row>
        <v-col v-show="commission.type !== 'Rebate'">
          <v-menu
            v-model="showingInvoiceDatePicker"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :messages="invoiceDateMessage"
                v-model="commission.invoiceDate"
                label="Invoice Date"
                :error-messages="
                  hasClickedSave && !commission.invoiceDate
                    ? 'Please enter a date'
                    : ''
                "
                prepend-icon="mdi-calendar"
                :error="hasClickedSave && !commission.invoiceDate"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="commission.invoiceDate"
              @input="
                showingInvoiceDatePicker = false;
                invoiceDateMessage = '';
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col>
          <v-text-field
            v-model="commission.vatSalesValue"
            prepend-icon="mdi-currency-gbp"
            label="Sales Value (VAT)"
            :error="hasClickedSave && !commission.vatSalesValue"
            :error-messages="
              hasClickedSave && !commission.vatSalesValue
                ? 'Please enter a Vat Included Value'
                : ''
            "
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <trev-zoho-deals-search v-model="zohoDeal"></trev-zoho-deals-search>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-text-field
            :error="hasClickedSave && !commission.poNumber"
            :error-messages="
              hasClickedSave && !commission.poNumber
                ? 'Please enter a PO Number'
                : ''
            "
            prepend-icon="mdi-pound-box-outline"
            label="PO Number"
            v-model="commission.poNumber"
            @input="zohoSyncPONumberMessage = ''"
            :messages="zohoSyncPONumberMessage"
          >
          </v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            prepend-icon="mdi-currency-gbp"
            label="PO Sales Value"
            v-model="commission.poSalesValue"
            :error="hasClickedSave && !commission.poSalesValue"
            :error-messages="
              hasClickedSave && !commission.poSalesValue
                ? 'Please enter a PO Sales Value'
                : ''
            "
            :messages="zohoSyncPOSalesValueMessage"
            @input="zohoSyncPOSalesValueMessage = ''"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            :error="hasClickedSave && !commission.costPrice"
            :error-messages="
              hasClickedSave && !commission.costPrice
                ? 'Please enter a Cost Price'
                : ''
            "
            prepend-icon="mdi-currency-gbp"
            label="Cost Price"
            v-model="commission.costPrice"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            prepend-icon="mdi-ticket-confirmation-outline"
            label="Quote Number"
            v-model="commission.quoteNumber"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-btn color="primary" @click="addCommission">
            <v-icon class="mr-2">mdi-plus</v-icon>
            {{ $route.params.commissionId ? "Update" : "Add" }} Commission
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
    <v-dialog
      v-model="isShowingCustomCommissionRate"
      max-width="50vh"
      @click:outside="commission.rate = 8"
    >
      <v-card>
        <v-toolbar>
          <v-toolbar-title>
            <v-icon class="mr-2">mdi-asterisk</v-icon>
            Set Custom Commission Rate
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            class="mt-6"
            type="number"
            label="Custom Commission Rate"
            v-model="newCustomCommissionRate"
          ></v-text-field>
          <v-btn class="mt-4" color="primary" @click="addCommissionRate">
            <v-icon class="mr-2"> mdi-floppy </v-icon>
            Save Rate
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
import TrevCommissionType from "../../../../components/commission/trev-commission-type.vue";
import trevCompanySearch from "../../../../components/companies/trev-company-search.vue";
import TrevXeroInvoiceSearch from "../../../../components/Xero/trev-xero-invoice-search.vue";
import TrevZohoDealsSearch from "../../../../components/ZohoDeals/trev-zoho-deals-search.vue";
export default {
  components: {
    trevCompanySearch,
    TrevCommissionType,
    TrevZohoDealsSearch,
    TrevXeroInvoiceSearch,
  },
  computed: {
    getCommissionRateItems() {
      return this.commissionRates.sort((a, b) => {
        return a - b;
      });
    },
  },
  created() {
    if (this.$route.params.commissionId) {
      this.$courier.Commissions.getById(this.$route.params.commissionId).then(
        (commission) => {
          this.$courier.Companies.getById(commission.forCompanyId).then(
            (forcompany) => {
              this.forCompany = forcompany;
              if (commission.invoiceId) {
                this.$courier.Commissions.findInvoiceById(
                  commission.xeroTenantId,
                  commission.invoiceId
                ).then((foundInvoice) => {
                  this.commission = commission;
                  this.invoice = foundInvoice;
                });
              } else {
                this.commission = commission;
              }
            }
          );

          this.$courier.ZohoDeals.getById(commission.zohoDealId).then(
            (deal) => {
              this.zohoDeal = deal;
            }
          );
        }
      );
    }
  },
  watch: {
    invoice: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && newValue.date) {
          this.commission.invoiceDate = newValue.date.substring(
            0,
            newValue.date.indexOf("T")
          );
          this.commission.invoiceNumber = newValue.invoiceNumber;
          this.commission.vatSalesValue = newValue.totalWithTax;
          this.commission.invoiceId = newValue.id;
          this.invoiceDateMessage = "Autofilled from selected invoice";
          this.commission.lastQuarterChange = this.commission.invoiceDate;
        }
      },
    },
    forCompany: {
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && newValue.defaultXeroTenantId) {
          this.commission.xeroTenantId = newValue.defaultXeroTenantId;
          this.commission.forCompanyId = newValue.id;

          if (this.commission.type !== "Rebate") {
            this.$nextTick(() => {
              this.$refs.ddlInvoiceSearch.getData();
            });
          }
        } else {
          this.commission.xeroTenantId = "";
        }
      },
    },
    zohoDeal: {
      handler(newValue, oldValue) {
        if (newValue) {
          //copy shit

          if (newValue.Vendor_PO_Xero) {
            this.commission.poNumber = newValue.Vendor_PO_Xero;
            this.zohoSyncPONumberMessage = "Autofilled from selected Zoho Deal";
          }

          if (newValue.customer_PO_Value) {
            this.commission.poSalesValue = newValue.customer_PO_Value;
            this.zohoSyncPOSalesValueMessage =
              "Autofilled from selected Zoho Deal";
          }

          if (newValue.deal_Name) {
            this.commission.zohoDealName = newValue.deal_Name;
          }

          if (newValue.id) {
            this.commission.zohoDealId = newValue.id;
          }
        }
      },
    },
    "commission.rate": {
      handler() {
        if (this.commission.rate === "custom") {
          this.isShowingCustomCommissionRate = true;
        }
      },
    },
  },
  methods: {
    addCommission() {
      this.hasClickedSave = true;

      this.commission.parentUserId = this.$store.getters.userId;

      //check all of the commission properties are properly filled out

      //destructure all of the properties that are allowed to be null



      const {
        quoteNumber,
        forCompany,
        notes,
        parentUser,
        timeLine,
        invoiceId,
        invoiceDate,
        invoiceNumber,
        ...restOfIt
      } = this.commission;

      console.log(restOfIt);

      var isAllGood = Object.values(restOfIt).every((x) => !!x);
      if (isAllGood) {
        //continue adding

        this.commission.xeroTenantId = this.invoice.tenantId;

        if (this.$route.params.commissionId) {
          //is editing
          this.$courier.Commissions.updateById(
            this.commission.id,
            this.commission
          ).then((newCommission) => {
            this.$courier.Commissions.withId(newCommission.id)
              .TimeLine.add({
                text: "Edited",
                icon: "mdi-clock-edit-outline",
                colour: "warning",
              })
              .then(() => {
                this.$router.push({
                  name: "View Commission",
                  params: {
                    commissionId: newCommission.id,
                  },
                });
              });
          });
        } else {
          //is adding
          this.$courier.Commissions.add(this.commission).then(
            (newCommission) => {
              //add the timeline action of created

              this.$courier.Commissions.withId(newCommission.id)
                .TimeLine.add({
                  text: "Created",
                  icon: "mdi-asterisk",
                  colour: "success",
                })
                .then(() => {
                  this.$router.push({
                    name: "View Commission",
                    params: {
                      commissionId: newCommission.id,
                    },
                  });
                });
            }
          );
        }
      } else {
        //dont let adding
      }
    },
    addCommissionRate() {
      this.commissionRates = this.commissionRates.filter(
        (x) => x.value != "custom"
      );
      this.commissionRates.push({
        text: this.newCustomCommissionRate + "%",
        value: this.newCustomCommissionRate,
      });
      this.commissionRates.push({ text: "Custom", value: "custom" });
      this.commission.rate = this.newCustomCommissionRate;
      this.newCustomCommissionRate = "";

      this.isShowingCustomCommissionRate = false;
    },
  },
  data() {
    return {
      showingLastQuarterChangeDatePicker: false,
      hasClickedSave: false,
      commission: {
        type: "",
        forCompanyId: "",
        invoiceNumber: "",
        invoiceDate: "",
        status: "Awaiting Approval",
        rate: "",
        poNumber: "",
        costPrice: "",
        poSalesValue: "",
        vatSalesValue: "",
        quoteNumber: "",
        xeroTenantId: "",
        parentUserId: "",
        invoiceId: "",
        zohoDealId: "",
        zohoDealName: "",
      },
      zohoSyncPONumberMessage: "",
      zohoSyncPOSalesValueMessage: "",
      invoiceDateMessage: "",
      invoice: {},
      zohoDeal: null,
      showingInvoiceDatePicker: false,
      forCompany: {},
      commissionRates: [
        { text: "8%", value: 8 },
        { text: "9%", value: 9 },
        { text: "16%", value: 16 },
        { text: "17%", value: 17 },
        { text: "Custom", value: "custom" },
      ],
      isShowingCustomCommissionRate: false,
      newCustomCommissionRate: 0,
    };
  },
};
</script>

<style></style>