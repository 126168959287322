<template>
    <div>
        <v-select :error="isInError" :error-messages="errorMessages" prepend-icon="mdi-format-list-bulleted-type"
            label="Commission Type" :items="types" v-model="selectedType"></v-select>
        <v-dialog v-model="isShowCustom" max-width="40vh" @click:outside="selectedType = 'Product'">
            <v-card>
                <v-toolbar>
                    <v-toolbar-title>
                        <v-icon class="mr-2">
                            mdi-asterisk
                        </v-icon>
                        Custom Commission Type
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text class="pb-0">
                    <v-text-field label="Custom Type" v-model="customType">
                    </v-text-field>
                </v-card-text>
                <v-card-text>
                    <v-btn color="primary" @click="addCustomType">
                        <v-icon class="mr-2">
                            mdi-plus
                        </v-icon>
                        Add Custom Type
                    </v-btn>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: String,
            default: ''
        },
        isInError: {
            type: Boolean,
            default: false
        },
        errorMessages: {
            type: String,
            default: ''
        }
    },
    watch: {
        value() {
            if (this.value) {
                if (!this.types.some(x => x === this.value)) {
                    this.types.push(this.value);
                }

                if (this.value != this.selectedType) {
                    this.selectedType = this.value;
                }
            }
        },
        selectedType(newValue, oldValue) {
            if (newValue == 'Custom') {
                this.isShowCustom = true;
            }
            else {
                this.$emit('input', newValue);
            }
        }
    },
    methods: {
        addCustomType() {
            this.types.push(this.customType);
            this.selectedType = this.customType;

            this.$emit('input', this.customType);

            this.customType = '';
            this.isShowCustom = false;
        }
    },
    data() {
        return {
            selectedType: '',
            isShowCustom: false,
            customType: '',
            types: [
                'Product',
                'Services',
                'Hardware',
                'Rebate',
                'Custom'
            ]
        }
    },
}
</script>

<style></style>