<template>
  <v-autocomplete
    :error="inError"
    :disabled="disabled"
    :messages="formMessage"
    prepend-icon="mdi-cash"
    label="Type to search for Invoices in Xero"
    :items="invoices"
    :loading="isLoading"
    :search-input.sync="search"
    hide-no-data
    hide-selected
    v-model="internalValue"
    return-object
    item-value="id"
    item-text="invoiceNumber"
  >
  </v-autocomplete>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    formMessage: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    inError: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: String,
      default: "",
    },
  },
  watch: {
    search(newValue, oldValue) {
      clearTimeout(this.timeOutId);

      this.timeOutId = setTimeout(() => {
        this.getData();

        //this.$emit('input', this.search);
      }, 300);
    },
    internalValue(newValue, oldValue) {
      this.$emit("input", this.internalValue);
    },
    value() {
      if (this.search != this.value.invoiceNumber) {
        console.log("setting invoice number");
        this.invoices.push(this.value);
        this.search = this.value.invoiceNumber;
        this.internalValue = this.value;
      }
    },
  },
  created() {
    if (this.tenantId) {
      this.getData();
    }
  },
  methods: {
    async getData() {
      try {
        this.isLoading = true;

        var tenantIds = this.$store.getters.getXeroProfiles.map(
          (x) => x.tenantId
        );

        var results = [];

        for (const tenantId of tenantIds) {
          var innerResults = await this.$courier.Commissions.findInvoices(
            tenantId,
            1,
            this.search
          );

          for(const invoice of innerResults)
          {
            invoice.tenantId = tenantId;
          }

          results = results.concat(innerResults);
        }
        console.log(results);
        this.isLoading = false;
        if (results && results.length > 0) {
          this.invoices = results;

          if (this.value) {
            this.invoices.push(this.value);
          }

          this.invoices = this.invoices.filter((x) => !!x.id);
        }
      } catch (exception) {
        console.error(exception);
      }
    },
  },
  data() {
    return {
      internalValue: null,
      invoices: [],
      isLoading: false,
      search: "",
      timeOutId: "",
    };
  },
};
</script>

<style></style>
